.invoice_wrapper {
  /* page-break-before: always; */
  width: 700px;
  position: relative;
  color: #292727;
  font-size: 15px;
  /* padding: 0px 5px; */
}

/* General container styling */
/* #invoice-container {
  font-family: Arial, sans-serif;
  margin: 20px auto;
  padding: 20px;
  width: 100%;
  height: 100% !important;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
} */

/* Header styling */
.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  /* border-bottom: 2px solid #ddd; */
  /* padding-bottom: 5px; */
  /* width: 100%; */
}

.sold-by {
  width: 50%;
}

.invoice-title {
  text-align: right;
  width: 50%;
}

.invoice-title h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.invoice-title p {
  margin: 4px 0;
  color: #555;
}

/* Customer Details */
.customer-details {
  margin-bottom: 20px;
}

.customer-details h4 {
  margin-bottom: 5px;
  color: #006400;
}

.customer-details p {
  margin: 3px 0;
}

/* Table Styling */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th,
.invoice-table td {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

.invoice-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.invoice-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Terms and Conditions */
.terms-conditions {
  margin-bottom: 20px;
}

.terms-conditions h4 {
  margin-bottom: 10px;
  color: #006400;
}

.terms-conditions ul {
  margin: 0;
  padding-left: 20px;
}

.terms-conditions ul li {
  margin-bottom: 5px;
}

/* Footer */
.invoice-footer {
  text-align: center;
  font-size: 14px;
  color: #555;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.invoice-footer strong {
  color: #333;
}

.title_text {
  font-size: 16px;
  font-weight: 700;
  color: #27a527;
}

@media print {
  /* body * {
    visibility: hidden;
  } */

  /* .printable_content,
  .printable_content * {
    visibility: visible;
    color: black;
  } */

  /* .printable_content {
    position: absolute;
    border: 1px solid blue;
    top: 50px;
    left: 0px;
  } */

  body * {
    visibility: hidden;
    overflow: hidden;
  }
  .printable_content * {
    visibility: visible;
  }

  .printable_content {
    position: absolute;
    left: 0;
    top: 0;
  }

  /* .row {
    page-break-inside: unset;
  } */
}

@media screen {
  .print_visible {
    display: none;
    text-align: start;
  }
}
